<template>
  <div v-if="segnalazione" class="segn-az">
    <b-card
      class="text-center"
    >
        <b-card-title class="mx-1">
          <b-row class="mb-1" align-h="between">
            <b-button
              class="mx-1"
              text="mx-1"
              variant="outline-warning"
              @click="$router.back()"
            >
             Indietro
            </b-button>
            <b-button
              v-if="!segnalazione.closedAt"
              class="mx-1"
              variant="danger"
              @click="showModal"
            >
              Chiudi Segnalazione
            </b-button>
            <b-card-text v-if="segnalazione.closedAt">
                    Chiusa il: {{new Date(segnalazione.closedAt).toLocaleString('it-IT', dateOptions)}}
                  
            </b-card-text>
            <b-card-text v-if="segnalazione.closedAt">
              Chiusa da: <a v-if="segnalazione.closedBy">{{segnalazione.closedBy}}</a>
                    <a v-if="!segnalazione.closedBy">Anonimo</a>
            </b-card-text>

            <b-button
              class="mx-1"
              text="mx-1"
              variant="danger"
              @click="showModalExt"
              v-if="segnalazione.closedAt"
            >
             Invia una segnalazione esterna
            </b-button>
            <!--
            <b-card-title v-else class="mx-1 text-danger">
              <b-row align-v="center" align-h="center">
                <b-col>
                  <b-card-text class="text-left">
                    Chiusa il:
                  </b-card-text>
                  {{new Date(segnalazione.closedAt).toLocaleString('it-IT', dateOptions)}}
                </b-col>
                <b-col>
                  <
                </b-col>
              </b-row>
            </b-card-title>
            -->
          </b-row>
          <b-row class="p-1">
            {{segnalazione.title}}
          </b-row>
          <b-row class="p-1">
            <b-card-text class="text-muted h6 mr-2">
              <feather-icon icon="CalendarIcon" />
              Inserita il:
            </b-card-text>
            {{new Date(segnalazione.createdAt).toLocaleString('it-IT', dateOptions)}}
          </b-row>
          <b-row v-if="segnalazione.name || segnalazione.surname" class="p-1">
            <b-card-text class="text-muted mr-2">
              <feather-icon icon="UserIcon" />
              Nome:
            </b-card-text>
            {{segnalazione.name}}
            {{segnalazione.surname}}
          </b-row>
          <b-row v-else class="p-1">
            <b-card-text class="text-muted mr-2">
              <feather-icon icon="UserIcon" />
              Utente: <strong>anonimo</strong>
            </b-card-text>
          </b-row>
          <b-row v-if="segnalazione.email" class="p-1">
            <b-card-text class="text-muted mr-2">
              <feather-icon icon="MailIcon" />
              email:
            </b-card-text>
            {{segnalazione.email}}
          </b-row>
          <b-row v-if="segnalazione.awaitAnswer" class="p-1">
            <b-card-text class="text-muted mr-2">
              Utente ha richiesto di ricevere risposta
            </b-card-text>
          </b-row>
        </b-card-title>
    </b-card>

    <b-card
      :bg-variant= "diff ? 'light' : 'default'"
    >
      <b-card-text class="text-left h6 text-muted">dalla più alla meno recente.</b-card-text>
      <app-timeline>
        <app-timeline-item
          v-for="item in reverseContenutos"
          :key="item.uuid"
          :subtitle="item.body"
          :time="`${new Date(item.createdAt).toLocaleString('it-IT', dateOptions)}`"
          :icon="item.userUuid ? 'UserIcon' : 'ShieldIcon'"
          :variant="item.userUuid ? 'success' : 'warning'"
          :userFullname="item.userUuid ? item.user.userDetail.name+' '+item.user.userDetail.surname : 'anon'"
          :file="item.file"
        />
      </app-timeline>
      <!-- <b-card-text class="text-right font-weight-bolder h6 text-success">meno recente</b-card-text> -->
    </b-card>

      <!-- <b-card> -->
    <b-card-actions
      v-if="!segnalazione.closedAt"
      title="Rispondi"
      action-collapse
      collapsed
    >
      <!-- <b-input-group>
         <b-form-input v-model="title" placeholder="Titolo" />
      </b-input-group> -->
      <b-input-group class="mt-1">
         <b-form-textarea
            v-model="body"
            rows="7"
            placeholder="Inserisci almeno 10 caratteri"
          />
          <!--
            Validation
            add to textarea
            :state="body.length >= 10"
          -->
      </b-input-group>
      <b-row align-h="end">
        <div class="w-25">
          <b-input-group class="mt-1">
            <b-form-file
              class="form-control"
              v-model="upload_file"
              :state="upload_file ? Boolean(upload_file) : null"
              placeholder="Scegli un file da allegare..."
              drop-placeholder="Trascina qui il file..."
              browse-text="Allega"
            />
          </b-input-group>
          <!-- <div v-if="upload_file" class="text-success">File: {{ upload_file ? upload_file.name : '' }}</div> -->
        </div>
        <b-button
          class="m-1"
          :variant="`${isDark ? 'warning' : 'primary'}`"
          @click="warnErrorsHints"
        >
          Invia
        </b-button>
      </b-row>
    <!-- </b-card> -->
    </b-card-actions>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormFile, BInputGroup,
  BCard, BCardText, BCardTitle, BLink, BButton, BFormTextarea, BModal
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import useAppConfig from '@core/app-config/useAppConfig'
// import { computed } from '@vue/composition-api'
import { computed } from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  data() {
    return {
      segnalazione: null,
      timestamp: null,
      body: '',
      dateOptions: {  timeStyle:"short", dateStyle: 'medium' },
      diff: false,
      upload_file: null,
    }
  },
  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')
    return { isDark }
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormFile,
    BInputGroup,
    BCard,
    BCardText,
    BCardTitle,
    BLink,
    BButton,
    AppTimeline,
    AppTimelineItem,
    BFormTextarea,
    BCardActions,
    BModal,
  },
  mounted() {
    // console.log(this.$route.params);
    this.getOneSegnalazioneAzienda()
    // setInterval(() => { this.currentDateTime() }, 1000)
  },
  computed: {
    reverseContenutos() {
      return this.segnalazione && this.segnalazione.contenutos ? this.segnalazione.contenutos.reverse() : []
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    warnErrorsHints() {
      if (this.upload_file && this.upload_file.size > 2 * 1024 * 1024) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File troppo grande',
            text: 'Inserire un file 2MB max',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      } else {
        this.submitSegnalazione()
      }
    },
    getOneSegnalazioneAzienda() {
      this.$http.post('/az/getOne', {
          uuid: this.$route.params.uuid
        }).then(res => {
        // console.log('Segnalazione ',res)
        this.segnalazione = res.data
      })
    },
    submitSegnalazione() {
      if (this.body && this.body.length > 9) {
        this.$http.post('/az/updateSegnalazione',
          this.buildSaveObject_new()
        ).then(res => {
          // console.log('Segnalazione UPDATE ',res)
          if (res && res.status == 200 && res.data) {
            this.titolo = ''
            this.body = ''
            this.upload_file = null
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Segnalazione',
                text: 'Risposta inserita nel sistema',
                icon: 'SlackIcon',
                variant: 'success',
              },
            })
            // update contenutos
            // if (res.data && res.data.segn) {
            //   this.segnalazione = res.data.segn
            // }
            this.segnalazione.contenutos = res.data.segn.contenutos
            this.diff = true;
            setInterval(() => {
              this.diff = false;
            }, 1000);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Errore',
                icon: 'AlertTriangleIcon',
                variant: 'error',
              },
            })
          }
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Messaggio non completo',
            text: 'Inserire contenuto di almeno 10 caratteri',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      }
    },
    buildSaveObject() {
      return {
        uuid:  this.segnalazione.uuid,
        contenutos: {
          body: this.body,
          segnalazioneUuid: this.segnalazione.uuid,
          userUuid: localStorage.userUuid
        }
      }
    },
    buildSaveObject_new() {
      const formData = new FormData();
      formData.append('uuid', this.segnalazione.uuid);
      formData.append('contenutos[body]', this.body);
      formData.append('contenutos[segnalazioneUuid]', this.segnalazione.uuid);
      formData.append('contenutos[userUuid]', localStorage.userUuid);

      if (this.upload_file) {
        formData.append('contenutos[upload_file]', this.upload_file, this.upload_file.name);
      }
      return formData;
    },
    async showModal() {
      try {
        let modalObj = {
          title: "Chiudi segnalazione",
          okTitle: "OK",
          cancelTitle: "Annulla", 
        }
        const result = await this.$bvModal.msgBoxConfirm("Dopo la conferma non sarà più possibile rispondere", modalObj);

        if (result === true)
          this.closeSegnalazione()
          // alert("OK button was pressed");
        else if (result === false)
          console.warn('Canceled');
          // alert("CANCEL button was pressed");
        else if (result === null)
          console.warn('Exit');
          // alert("The modal was closed by the user using other means. (ESC, Backdrop, ect..)");
      } catch {
        console.warn('Error');
        // alert("The modal was destroyed before the user could make a decision");
      }
    },
    async showModalExt() {
      try {
        let modalObj = {
          title: "Invia Segnalazione esterna",
          okTitle: "Ho letto e confermo di voler procedere",
          cancelTitle: "Annulla",
        }
        const result = await this.$bvModal.msgBoxConfirm("Vuoi proseguire nella segnalazione con canali esterni senza utilizzare il canale interno dell’azienda? A norma dell’art. 6 lettera C e D del D.lgs. 24/2023 ciò è possibile solo al ricorrere delle seguenti condizioni: (i) la persona segnalante ha fondati motivi di ritenere che, se effettuasse una segnalazione interna, alla stessa non sarebbe dato efficace seguito ovvero che la stessa segnalazione possa determinare il rischio di ritorsione; (ii) la persona segnalante ha fondato motivo di ritenere che la violazione possa costituire un pericolo imminente o palese per il pubblico interesse.", modalObj);

        if (result === true)
          window.location.href = 'https://whistleblowing.anticorruzione.it/#/';
          // alert("OK button was pressed");
        else if (result === false)
          console.warn('Canceled');
          // alert("CANCEL button was pressed");
        else if (result === null)
          console.warn('Exit');
          // alert("The modal was closed by the user using other means. (ESC, Backdrop, ect..)");
      } catch {
        console.warn('Error');
        // alert("The modal was destroyed before the user could make a decision");
      }
    },
    closeSegnalazione() {
      this.$http.post('/az/closeSegnalazione', { uuid: this.segnalazione.uuid }).then(res => {
        // this.segnalazione = res.data.segn
        if (res && res.status == 200) {
          this.titolo = ''
          this.body = ''
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Segnalazione chiusa',
              icon: 'SlackIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'home'})
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Errore',
              icon: 'AlertTriangleIcon',
              variant: 'error',
            },
          })
        }
      })
    },
  },
}
</script>

<style>
.segn-az .b-overlay-wrap.position-relative h4.card-title {
  text-align: center;
  border-color: #312783 !important;
  border: 1px solid transparent;
  padding: 0.786rem 1.5rem;
  border-radius: 0.358rem;
  display: inline-block;
  font-weight: 400;
  color: #4b4b4b !important;
  vertical-align: middle;
  user-select: none;
  font-size: 1rem;
  line-height: 1;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s, border 0s;
}
</style>
